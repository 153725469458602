import React,{useState,useEffect} from 'react';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
        Card,Box, Typography} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import moment from 'moment';
import { obtenDispo } from '../funciones/IdDispositivo';
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import {llamaApiCargaToken, ErrorGeneral } from '../funciones/ClienteAxios';
const useStyles = makeStyles({
    estiloCard:{
      width: '58rem',
      boxShadow:'0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%)',
      // color:'#4a4a4a',
      display:'block',
      padding:'.5rem',
      maxHeight:'100%',
      minHeight: 280,
      marginRight:'.5rem'
    },
    tablaContainer: {
      width: '57rem',
      maxHeight: '26.5rem',
      minHeight: 280,
      //minWidth:'100%',
    },
    tablaContainer2: {
      width: '27rem',
      maxHeight: '26.5rem',
      minHeight: 280,
      //minWidth:'100%',
    },
    totalEstilo:{
      fontSize:'1.25rem',
      fontWeight:'bold'
    },
    tituloEstilo:{
      paddingBottom:'.25rem',
      fontWeight:'bold'
    },
    tableRow: {
      "&$selected, &$selected:hover": {
        backgroundColor:process.env.REACT_APP_Color_Second
      }
    },
    tableCell: {
      "$selected &": {
        color: "white"
      }
    },
    hover: {},
    selected: {}
  }); 

export default function TablaPromovidos({listAv}){    
  const classes = useStyles();      
  let history = useHistory();    
  const auxDispV =obtenDispo()
  const source = axios.CancelToken.source();  
  const [datos,setDatos] = useState([]);     
  const [espera,setEspera] = useState(false);     
  const [registro,setRegistro] = useState([]);    
  const [domic,setDomic] = useState([]);    
  const [listDomic,setListDomic] = useState([]);     
  const colFondo=process.env.REACT_APP_Fondo_Color
  const auxEdo=process.env.REACT_APP_EDO  
  useEffect(()=>{    
    //console.log(listAv);
    if (listAv.length!==0 &&listAv && listAv[0].keyId!=="0") 
    {  
      setDatos(listAv)  
   
    }
    else
    { setDatos([])
      setListDomic([])  
    }  
          
    return ()=> {
      source.cancel();
    } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[listAv])          

  const getListDomic = (auxLlave) => {
    const data = qs.stringify({
      Estado:auxEdo,  
      Llave:auxLlave,
      idDispositivo: auxDispV,
    });
    const url = "";
    const urlExt="https://cluster-bd.daduva.mx/api/mx/v1/zudoku/search/name/domicilio"
    function respuestaSecc(auxiliar) {
      if (auxiliar[0].Id != -1) {                
        setListDomic(auxiliar)  
      }
      else{
        setListDomic([])  
      }
    }

    llamaApiCargaToken(data, url, respuestaSecc, ErrorGeneral, setEspera, history, source,urlExt);
  }

  const seleccionRow =(regis)=>{       
    setRegistro(regis)     
    getListDomic(regis.keyId)                      
  } 

  const celda = (alinear, estilo, contenido,index) => {
    return (
    <TableCell align={alinear} key={index} className={classes.tableCell} 
      padding={'none'} style={estilo} >
      {contenido}
    </TableCell>)
  }

  const CelTitl=({index,titulo,aline,estilo})=> celda(aline?aline:"center", {...estilo,backgroundColor:colFondo,color:'white',}, titulo?titulo:"",index) 

  const colorStatus=(status)=>{
    let stdColor=""
    if (status<3) 
    { stdColor="#E91E63"      
    }
    else
    { if (status<5) 
      {
        stdColor="#f07111"      
      }
      else
      {
        if (status<7) 
        { stdColor="#FBBE06"      
        }
        else
        { stdColor="#19A11E"                        
        }         
      } 
    }
    return(
    <Box display={"flex"}  justifyContent="flex-end" style={{textAlign:"right",alignItems:"center",fontSize:"12px"}}>            
      {`${status}`}
      <FiberManualRecordIcon fontSize="small" style={{color:stdColor,}}/>      
    </Box>)
  }  

  const formatoNom=(registro)=>{
    const respuesta=`${registro.Nom} ${registro.Pat} ${registro.Mat}`
    return primerformato(respuesta)
  }

  const primerformato = str => {
    return str.toLowerCase().replace(/\b[a-z]/g, c => c.toUpperCase())
  }

  const obtenerGen=(aux)=>{
    let respuesta=""
    switch (aux) {
      case 'H':
        respuesta="Hombre"
        break;
      case 'M':
        respuesta="Mujer"
        break;
      case 'X':
        respuesta="No Binario"
        break;
      case 'N':
        respuesta="Pendiente"
        break;
      default:
        break;
    }
    return respuesta
  }

  const formato=(campo,leyenda)=>{
    if (leyenda!=="") {
      return campo!==""? `${leyenda} ${campo}`:''
    }else{
      return campo!==""?`${campo}, `:''
    }
    
  }
  const obtnDir=(regis)=>{
    const {Col,Calle,NoExt,NoInt}=regis
    return`${formato(Col,"")} ${Calle} ${formato(NoExt,"NoExt")} ${formato(NoInt,"NoInt")}`
  }

  function TablaEstr(auxDatos) {        
    
    return(        
    <TableContainer className={classes.tablaContainer} id="tamTablaReg"  >
    <Table stickyHeader aria-label="sticky table">
      <TableHead >            
        <TableRow>         
        <CelTitl index={4} titulo={"INE"} aline={"center"} />         
          <CelTitl index={2} titulo={"Nombre " } aline={"center"} estilo={{fontSize:"13px"}} />                                              
          <CelTitl index={2} titulo={"Municipio" } aline={"center"} estilo={{fontSize:"13px"}} />
          <CelTitl index={2} titulo={"Sección" } aline={"center"} estilo={{fontSize:"13px"}} />
          <CelTitl index={2} titulo={"Genero" } aline={"center"} estilo={{fontSize:"13px"}} />           
          <CelTitl index={2} titulo={"F. Nac" } aline={"center"} estilo={{fontSize:"13px"}} />                                    
          <CelTitl index={5} titulo={"Dirección"} estilo={{fontSize:"13px"}} aline={"center"} />                   
          {/* <CelTitl index={5} titulo={"Télefono"} estilo={{fontSize:"13px"}} aline={"center"} />                    */}
        </TableRow>        
      </TableHead>  
      <TableBody>         
      {auxDatos.map((elemnto,index) => {  
        const auxVal=registro?.keyId === elemnto?.keyId ?true:false       
        const auxNac=moment.utc( elemnto.Fnac).format('YYYY-MM-DD HH:mm') === "1900-01-01 00:00"
        ? "" : moment.utc( elemnto.Fnac).format('DD/MM/YYYY')                                           
        return (                  
        <TableRow className={classes.tableRow} selected={ auxVal} 
          classes={{ hover: classes.hover, selected: classes.selected }} 
          key={index+1} onClick={ () => seleccionRow(elemnto) }  
        > 
          {celda("center", {width:"8rem", fontSize:"11px",paddingRight:".5rem"},elemnto.Ine ,index) }            
          {celda("left", {width:"10rem", fontSize:"11px",marginRight:".2rem"}, formatoNom(elemnto) ,index+1) }      
          {celda("left", {width:"10rem", fontSize:"12px",marginRight:".2rem"}, elemnto.Muni ,index+2) }                    
          {celda("center", {width:"3rem", fontSize:"12px",marginRight:".2rem"}, elemnto.Secc ,index+3) }                    
          {celda("center", {width:"4rem", fontSize:"12px",marginRight:".2rem"}, obtenerGen(elemnto.Gen) ,index+3) }                    
          {celda("center", {width:"4rem", fontSize:"12px",marginRight:".2rem"}, auxNac ,index+3) }                    
          {celda("center", {width:"20rem", fontSize:"11px"}, obtnDir(elemnto),index+5) }
          {/* celda("right", {width:"2.4rem", fontSize:"12px"}, elemnto.Tel,index+6)  */}          
        </TableRow>              
        );                        
      })}                                                
      </TableBody>
    </Table>
    </TableContainer>        
    ); 
  }

  function TablaDomicilio(auxDatos) {        
    
    return(        
    <TableContainer className={classes.tablaContainer2} id="tamTablaReg"  >
    <Table stickyHeader aria-label="sticky table">
      <TableHead >            
        <TableRow>         
        <CelTitl index={4} titulo={"INE"} aline={"center"} />         
          <CelTitl index={2} titulo={"Nombre " } aline={"center"} estilo={{fontSize:"13px"}} />                                    
          
          <CelTitl index={5} titulo={"Edad"} estilo={{fontSize:"13px"}} aline={"center"} />                     
         
        </TableRow>        
      </TableHead>  
      <TableBody>         
      {auxDatos.map((elemnto,index) => {  
         const auxVal=domic?.keyId === elemnto?.keyId ?true:false                                              
        return (                  
        <TableRow className={classes.tableRow} selected={auxVal} 
          classes={{ hover: classes.hover, selected: classes.selected }} 
          key={index+1} onClick={ () => {} }  
        > 
          {celda("center", {width:"3rem", fontSize:"12px"},elemnto.Ine ,index+4) }            
          {celda("left", {width:"10rem", fontSize:"12px"}, formatoNom(elemnto) ,index+2) }                    
          {celda("center", {width:"2.4rem", fontSize:"12px"}, elemnto.Edad,index+5) }
          {/* celda("right", {width:"2.4rem", fontSize:"12px"}, elemnto.Tel,index+6)  */}          
        </TableRow>              
        );                        
      })}                                                
      </TableBody>
    </Table>
    </TableContainer>        
    ); 
  }
                       
  const llamada= datos.length !== 0 ?(TablaEstr(datos)):(<Box style={{paddingTop:1+'rem'}}>
                                  <Typography variant="h5" component="h5">No contiene datos</Typography>
                                  </Box>)
                                                                                                                                           
  return (
  <Box display={'flex'} flexDirection="row" style={{height: '26rem' }}>
    <Card className={classes.estiloCard} style={{height:window.innerHeight-140}}>                        
      {/* <Typography variant="body1" component="body1"></Typography>  */}
      {llamada}   
    </Card>
    {listDomic.length!==0?
      <Card>
        <Typography>Personas con el mismo domicilio</Typography>
        {TablaDomicilio(listDomic)}
      </Card>      
      :null
    }
  </Box>                    
  );
}