import React,{useEffect,useState} from 'react';
import {HorizontalBar,Bar} from 'react-chartjs-2';
import {Card,CardContent,Box, Typography} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

const arreColores=["#038545", "#EF8012", "#347AF4", "#FFC107", "#119A4F", "#B406D8", "#FFA318", "#DC4A3D", "#32CA81", 
"#F9B546", "#ED0808", "#0097A7", "#880E4F", "#4BAC7D", "#C52363", "#F57F06", "#7C594C", "#DBE772"]

const useStyles = makeStyles((theme) => ({
  contenedorCard:{
    //paddingRight:'3rem'
    height:10+'rem',marginRight:".3rem"
  },
  card:{
    boxShadow:'0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%)',
    //color:'#4a4a4a',
  },
  tituloGraf:{
    paddingTop:'0rem',
    textAlign:'center'
  },
  nested: {
    paddingLeft: theme.spacing(2),
  },
}));

export default function Graficas({valor}){
  const classes = useStyles();
  const color1= '#19A11E';  //ff1744  
  const color2= '#FBBE06';
  const auxColor=process.env.REACT_APP_Fondo_Color
  const [data,setData]= useState([]);
  const [datEdad,setDatEdad]= useState([]);
  const [datEstatus,setDatEstatus]= useState([]);
  const [datTipo,setDatTipo]= useState([]);
  const [bandera,setBandera]= useState(false);
  useEffect(()=>{    
    const{  
      HTot, MTot, XTot,
      Tot25, Tot35, Tot45,
      Tot55, Tot65, Tot70,
      Atendiendo, Cancelado, Cerrado,
      Revision, 

      }=valor[0]            
   // console.log(valor[0]     );
    if (valor[0].Tipo) {
      
      console.log(dataGeneral("Tipo",valor[0].Tipo)); 
      setDatTipo(dataGeneral("Tipo",valor[0].Tipo))
    }

    setData({Hombre:HTot,Mujer:MTot,Binario:XTot})
    setDatEstatus({TAtend:Atendiendo, TCancel:Cancelado, TCerrad:Cerrado,
      TRevis:Revision, })
    setDatEdad({Tot25:Tot25, Tot35:Tot35, Tot45:Tot45,
      Tot55:Tot55, Tot65:Tot65, Tot70:Tot70,})
   
    setBandera(true)
  },[valor])

  const dataGenero = {
    labels: ['Género'],
    datasets: [
    { type: 'bar', label:[ 'No Binario' ],
        data:[data.Binario],//[10, 20, 30, 40],
        backgroundColor:  [ "#4BAC7D50"]//'rgba(255, 99, 132, 0.2)',         
      },              
      { label:'Hombre Total',
        type: 'bar',
        data: [data.Hombre], //[50, 40, 30, 50],
        backgroundColor:'#4BAC7D',
        borderColor: '#4BAC7D',
        //fill: false,
        tension: 0.4           
      },
      { label:'Mujer Total',
        type: 'bar',
        data: [data.Mujer], //[50, 40, 30, 50],
        backgroundColor:'#C52363',
        borderColor: '#C52363',
        //fill: false,
        tension: 0.4   
        
      },   
    ],
  }; 

  const dataEstatus = {
    labels: ['Estatus'],
    datasets: [
    { type: 'bar', label:[ 'Atendiendo' ],
        data:[datEstatus.TAtend],//[10, 20, 30, 40],
        backgroundColor:  [ "#4BAC7D50"]//'rgba(255, 99, 132, 0.2)',         
      },              
      { label:'Cancelado',
        type: 'bar',
        data: [datEstatus.TCancel], //[50, 40, 30, 50],
        backgroundColor:'#4BAC7D',
        borderColor: '#4BAC7D',
        //fill: false,
        tension: 0.4           
      },
      { label:'Cerrado',
        type: 'bar',
        data: [datEstatus.TCerrad], //[50, 40, 30, 50],
        backgroundColor:'#C52363',
        borderColor: '#C52363',
        //fill: false,
        tension: 0.4   
        
      },   
      { label:'Revision',
        type: 'bar',
        data: [datEstatus.TRevis], //[50, 40, 30, 50],
        backgroundColor:'#B406D8',
        borderColor: '#B406D8',
        //fill: false,
        tension: 0.4           
      },               
    ],
  }; 

  const dataEdad = {
    labels: ['Edad'],
    datasets: [
    { type: 'bar', label:[ '18 a 25' ],
        data:[datEdad.Tot25],
        backgroundColor:  [ "#4BAC7D50"]
      },              
      { label:'26 a 35',
        type: 'bar',
        data: [datEdad.Tot35], 
        backgroundColor:'#4BAC7D',
        borderColor: '#4BAC7D',        
        tension: 0.4           
      },
      { label:'36 a 45',
        type: 'bar',
        data: [datEdad.Tot45], 
        backgroundColor:'#C52363',
        borderColor: '#C52363',        
        tension: 0.4           
      },   
      { label:'46 a 55',
        type: 'bar',
        data: [datEdad.Tot55], 
        backgroundColor:'#B406D8',
        borderColor: '#B406D8',      
        tension: 0.4           
      },   
      { label:'56 a 65',
        type: 'bar',
        data: [datEdad.Tot65], 
        backgroundColor:'#FFA318',
        borderColor: '#FFA318',        
        tension: 0.4           
      },   
      { label:'Mayor de 65',
        type: 'bar',
        data: [datEdad.Tot70],  
        backgroundColor:'#7C594C',
        borderColor: '#7C594C',        
        tension: 0.4   
      },   
    ],
  }; 


  const dataGeneral =(titulo, arrTipo)=> {
    let nvoArr=[]
    arrTipo.forEach((Tipo,index)=>
      {let auxColor=arreColores[index]
      if (Tipo.Id===0) {
        auxColor="#6882a1"
      }
      if (Tipo.Id===7) {
        auxColor="#ED0808"
      }
      nvoArr.push({type: 'bar', label:[ Tipo.Nom ],
        data:[ Tipo.Val],
        backgroundColor:  [auxColor ],
        tension: 0.4           
      })
      }
    )     
    const data={labels: [titulo], datasets:nvoArr }
    return data
  }; 
 
  const options = {
  
  //  indexAxis:'y',
    tooltips: {
      enabled: true,
    },
   /*  layout: {
      padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0
      }
    }, */
    title:{
      fontStyle: 'bold',
    },
    scales: {
      x: {
          display: true,
          title: {
          display: true
          }
      },
      y: {
          display: true,
          title: {
          display: false,
       
          },
       
      },
      }
   /*  scales: {
      x: {
        stacked: true,
        ticks: {
          beginAtZero: true,
        },
      },
      y: {
        stacked: true,
        labe :{
          fontSize:6,
        },
      }
    
    }, */
  } 

  function  llenadoTabla2(datos,nombre)
  {
    return(
    <Card className={classes.card} style={{paddingRight:.8+'rem',paddingLeft:.8+'rem' 
      ,paddingBottom:.5+'rem',height:10+'rem',width:25+'rem'}} >              
      <Bar
        id="graficaMuni"
        data={datos}
        width={400} height={200} 
        options={options}
      />   
    </Card>
    );
  }

  function  llenadoTabla1(datos,nombre)
  {
    return(
    <Card className={classes.card} style={{paddingRight:.8+'rem',paddingLeft:.8+'rem' 
      ,paddingBottom:.5+'rem',height:10+'rem',width:20+'rem'}} >              
      <Bar
        id="graficaMuni"
        data={datos}
        width={350} height={200} 
        options={options}
      />   
    </Card>
    );
  }

  return (
  <Box display="flex" flexDirection="row" justifyContent={'center'} mb={1} style={{marginTop:1+'rem'}} >           
     <Box display="flex"className={classes.contenedorCard}  >
        {bandera ?llenadoTabla1(dataGenero,):null}
      </Box>   
      <Box display="flex"className={classes.contenedorCard}  >
        {bandera ?llenadoTabla2(dataEdad,):null}
      </Box>    
      <Box display="flex"className={classes.contenedorCard}  >
        {bandera ?llenadoTabla2(dataEstatus,):null}
      </Box>  
      <Box>
        {  datTipo.length !==0 ? llenadoTabla2( datTipo,):null  }
      </Box>   
     {/*  <Box display="flex"className={classes.contenedorCard}  >
        {bandera ?llenadoTabla2(Grafica3,process.env.REACT_APP_Promov):null}
      </Box>    */}  
  </Box>
  );
}