import React,{useState,useEffect} from 'react';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
        Card,Box, Typography} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import NumberFormat from 'react-number-format';
const useStyles = makeStyles({
    estiloCard:{
      width: '32rem',
      boxShadow:'0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%)',
      // color:'#4a4a4a',
      display:'block',
      padding:'.5rem',
      maxHeight:'100%',
      minHeight: 280,
      marginRight:'.5rem'
    },
    tablaContainer: {
      width: '31.5rem',
      maxHeight: '26.5rem',
      minHeight: 280,
      //minWidth:'100%',
    },
    totalEstilo:{
      fontSize:'1.25rem',
      fontWeight:'bold'
    },
    tituloEstilo:{
      paddingBottom:'.25rem',
      fontWeight:'bold'
    },
    tableRow: {
      "&$selected, &$selected:hover": {
        backgroundColor:process.env.REACT_APP_Color_Second
      }
    },
    tableCell: {
      "$selected &": {
        color: "white"
      }
    },
    hover: {},
    selected: {}
  });

 

export default function TablaSeccion({listAv,registro, setRegistro,}){    
  const classes = useStyles();      
  const [datos,setDatos] = useState([]);     
  const [totalGen,setTotalGen] = useState([]);     
  const auxCol=process.env.REACT_APP_Fondo_Color  
  useEffect(()=>{    
    
    if (listAv.length!==0 &&listAv[0].avc_gral&& listAv[0].avc_gral[0].EdoId!==0) 
    { let aux={totHom:0, GenX:0, totMuj:0, cerrado:0, cancel:0, atend:0,revision:0}
      listAv[0].avc_gral.forEach(element => {
        aux={ totHom: aux.totHom + element.GenH,
          totMuj: aux.totMuj + element.GenM,
          GenX: aux.GenX + element.GenX,
          revision:aux.revision + element.Revision,
          cerrado: aux.cerrado + element.Cerrado,
          cancel: aux.cancel + element.Cancelado,
          atend: aux.atend + element.Atendiendo,
        }
      });
      setDatos(listAv[0].avc_gral)  
      setTotalGen(aux)
    }
    else
    { setDatos([])
    }  
       
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[listAv])          

  const onChangeTable =(regis)=>{       
    setRegistro(regis)                           
  }

  const celda = (alinear, estilo, contenido,index) => {
    return (
    <TableCell align={alinear} key={index} className={classes.tableCell} 
      padding={'none'} style={estilo} >
      {contenido}
    </TableCell>)
  }

  const CelTitl=({index,titulo,aline,estilo})=> celda(aline?aline:"center", {...estilo,backgroundColor:auxCol,color:'white',}, titulo?titulo:"",index) 

  const colorStatus=(status)=>{
    let stdColor=""
    if (status<25) 
    { stdColor="#E91E63"      
    }
    else
    { if (status<50) 
      {
        stdColor="#f07111"      
      }
      else
      {
        if (status<75) 
        { stdColor="#FBBE06"      
        }
        else
        { stdColor="#19A11E"                        
        }         
      } 
    }
    return(
    <Box display={"flex"}  justifyContent="flex-end" style={{textAlign:"right",alignItems:"center",fontSize:"12px"}}>            
      {`${status.toFixed(2)}%`}
      <FiberManualRecordIcon fontSize="small" style={{color:stdColor,}}/>      
    </Box>)
  }

  function TablaEstr(auxDatos) {        
    
    return(        
    <TableContainer className={classes.tablaContainer} id="tamTablaReg"  >
    <Table stickyHeader aria-label="sticky table">
      <TableHead >    
        { totalGen.length!==0 ?
        <TableRow>
          <CelTitl index={1} titulo={ "Sección" } aline={"left"} estilo={{fontSize:"13px"}} />         
          <CelTitl index={2} titulo={<span>Atendiendo <br/> <strong>
            <NumberFormat value={totalGen.atend} displayType={'text'}  thousandSeparator={true}/>
              </strong></span> 
            } aline={"center"} estilo={{fontSize:"13px"}} />   
          <CelTitl index={3} titulo={<span>Cancelado <br/><strong>
            <NumberFormat value={totalGen.cancel} displayType={'text'}  thousandSeparator={true}/></strong></span>  } 
            aline={"center"} estilo={{fontSize:"13px"}}/>
          <CelTitl index={3} titulo={<span>Cerrado <br/><strong>
            <NumberFormat value={totalGen.cerrado} displayType={'text'}  thousandSeparator={true}/></strong></span>  } 
            aline={"center"} estilo={{fontSize:"13px"}} />
          <CelTitl index={3} titulo={<span>Revision <br/><strong>
            <NumberFormat value={totalGen.revision} displayType={'text'}  thousandSeparator={true}/></strong></span>  } 
              aline={"center"} estilo={{fontSize:"13px"}} />
          <CelTitl index={4} titulo={"" } aline={"center"} />         
          <CelTitl index={5} titulo={<span>Hombre <br/><strong>
          <NumberFormat value={totalGen.totHom} displayType={'text'}  thousandSeparator={true}/></strong></span>} 
            estilo={{fontSize:"13px"}} aline={"center"} />         
          <CelTitl index={6} titulo={<span>Mujer <br/><strong>
          <NumberFormat value={totalGen.totMuj} displayType={'text'}  thousandSeparator={true}/></strong></span>} 
            estilo={{fontSize:"13px"}} aline={"center"} />         
          <CelTitl index={7} titulo={<span style={{fontSize:"12px"}}>No Binario <br/> <strong>
          <NumberFormat value={totalGen.GenX} displayType={'text'}  thousandSeparator={true}/></strong></span>} 
            estilo={{fontSize:"13px"}} aline={"center"} />                             
        </TableRow>
        :null }                   
      </TableHead>  
      <TableBody>         
      {auxDatos.map((elemnto,index) => {  
        const auxVal=registro?.MunId === elemnto.MunId ?true:false                                              
        return (                  
        <TableRow className={classes.tableRow} selected={ auxVal} 
          classes={{ hover: classes.hover, selected: classes.selected }} 
          key={index+1} onClick={ () => onChangeTable(elemnto) }  
        >   
          {celda("left", {width:"2.5rem"}, elemnto.SecNum ,index+1) }        
          {celda("right", {width:"6rem"},  <NumberFormat value={elemnto.Atendiendo} displayType={'text'}  thousandSeparator={true}/>  ,index+5) }
          {celda("right", {width:"4rem"},  <NumberFormat value={elemnto.Cancelado} displayType={'text'}  thousandSeparator={true}/>  ,index+5) }
          {celda("right", {width:"4rem"},  <NumberFormat value={elemnto.Cerrado} displayType={'text'}  thousandSeparator={true}/>  ,index+5) }
          {celda("right", {width:"4rem"},  <NumberFormat value={elemnto.Revision} displayType={'text'}  thousandSeparator={true}/>  ,index+5) }
          {celda("right", {width:"6rem"}, colorStatus(elemnto.Porcent),index+4) }
          {celda("right", {width:"4rem"}, <NumberFormat value={ elemnto.GenH} displayType={'text'}  thousandSeparator={true}/>,index+5) }
          {celda("right", {width:"4rem"}, <NumberFormat value={ elemnto.GenM} displayType={'text'}  thousandSeparator={true}/>,index+6) }
          {celda("right", {width:"4rem",paddingRight:".3rem"},  <NumberFormat value={ elemnto.GenX} displayType={'text'}  thousandSeparator={true}/>,index+7) }     
        </TableRow>              
        );                        
      })}                                                
      </TableBody>
    </Table>
    </TableContainer>        
    ); 
  }
                       
  const llamada= datos.length !== 0 ?(TablaEstr(datos)):(<Box style={{paddingTop:1+'rem'}}>
                                  <Typography variant="h5" component="h5">No contiene datos</Typography>
                                  </Box>)
                                                                                                                                           
  return (
  <Box display={'flex'} flexDirection="row" style={{height: '29rem' }}>
    <Card className={classes.estiloCard} style={{height:window.innerHeight-140}}>                        
      {/* <Typography variant="body1" component="body1"></Typography>  */}
      {llamada}   
    </Card>

  </Box>                    
  );
}