import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import {Box, IconButton} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

export default function PaginacionTab(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;
  
    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <Box style={{ flexShrink: 0, marginLeft: "1rem" }}>
        <IconButton    size='small'
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="primera página"
        >
          {theme.direction === 'rtl' ? <LastPageIcon  /> : <FirstPageIcon  />}
        </IconButton>
        <IconButton size='small'
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="página anterior"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight   /> : <KeyboardArrowLeft   />}
        </IconButton>
        <IconButton size='small' 
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="página siguiente"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft  /> : <KeyboardArrowRight  />}
        </IconButton>
        <IconButton size='small'
          onClick={handleLastPageButtonClick}  
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon  /> : <LastPageIcon   />}
        </IconButton>
      </Box>
    );
  }