import React,{useEffect,useState} from 'react';
import {PolarArea,Bar} from 'react-chartjs-2';
import {Card,Box, } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  contenedorCard:{
    //paddingRight:'3rem'
    //height:10+'rem'//,marginRight:".2rem"
  },
  card:{
    boxShadow:'0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%)',
    //color:'#4a4a4a',
  },
  tituloGraf:{
    paddingTop:'0rem',
    textAlign:'center'
  },
  nested: {
    paddingLeft: theme.spacing(2),
  },
}));

export default function GraficaEdad({listEdad}){
  const classes = useStyles();
  const auxColor=process.env.REACT_APP_Fondo_Color
  const [data,setData]= useState([]);
  const [listEtiq,setListEtiq]= useState([]);
  useEffect(()=>{    
    if (listEdad.length!==0) {
      let nvoArr=[],nvoArrEtiq=[]
      listEdad.forEach((edad)=>{
        nvoArr.push(edad.Total)
        nvoArrEtiq.push(edad.Nom)
      })      
      setData(nvoArr)  
      setListEtiq(nvoArrEtiq)
    }
    
    
  },[listEdad])

  const data2 = {    
    labels:listEtiq,
    datasets: [
      { type: 'pie', label:'Avance',
        data:data,//[10, 20"#038545", "#EF8012", "#347AF4", 30, 40],
        backgroundColor: ["#DBE772","#DC4A3D","#4BAC7D"] //"#4BAC7D50"
      },                    
    ],
  }; 

  const options = {
    
    tooltips: {
      enabled: true,
    },  
    title:{
      fontStyle: 'bold',
    },
    scales: {
      x: {
          display: true,
          title: {
          display: true
          }
      },
      y: {
          display: true,
          title: {
          display: false,
       
          },
       
      },
    }   
  } 

  function  llenadoTabla2(datos,nombre)
  {
    return(
    <Card className={classes.card} style={{paddingRight:.8+'rem',paddingLeft:.8+'rem' 
      ,paddingBottom:.5+'rem', }} >              
      <PolarArea
        id="graficaMuni1"
        data={datos}
        width={300} height={200} 
        options={options}
      />   
    </Card>
    );
  }

  return (
  <Box display="flex" flexDirection="row" justifyContent={'center'} mb={1}  ml={1} mr={1}>           
      <Box display="flex"className={classes.contenedorCard}  >
        {data.length!==0 ?llenadoTabla2(data2,):null}
      </Box>    
        
  </Box>
  );
}