import React,{useState,useEffect} from 'react';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
        Card,Box, Typography, IconButton, Tooltip, TablePagination,LinearProgress} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';
import { useHistory } from "react-router-dom";
import ModalInformativo from '../generales/ModalInformativo';
import { obtenDispo } from '../funciones/IdDispositivo';
import axios from 'axios';
import qs from 'qs';
import XLSX from 'xlsx';
import SaveIcon from '@material-ui/icons/Save';
import moment from 'moment';
import PaginacionTab from '../layout/PaginacionTab'
import { numeroPag } from "../funciones/Paginacion";
import {ErrorGeneral, llamaApiCargaToken,} from "../funciones/ClienteAxios";
const useStyles = makeStyles({
    estiloCard:{
      width: '28rem',
      boxShadow:'0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%)',
      // color:'#4a4a4a',
      display:'block',
      padding:'.5rem',
      maxHeight:'100%',
      minHeight: 280,
      marginRight:'.5rem'
    },
    tablaContainer: {
      width: '27.5rem',
      maxHeight: '26.5rem',
      minHeight: 280,
      //minWidth:'100%',
    },
    totalEstilo:{
      fontSize:'1.25rem',
      fontWeight:'bold'
    },
    tituloEstilo:{
      paddingBottom:'.25rem',
      fontWeight:'bold'
    },
    tableRow: {
      "&$selected, &$selected:hover": {
        backgroundColor:process.env.REACT_APP_Color_Second
      }
    },
    tableCell: {
      "$selected &": {
        color: "white"
      }
    },
    hover: {},
    selected: {}
  });
 
export default function TablaAvSecc({modalAbierto,setModalAbierto,auxSecc, fechas}){    
  const classes = useStyles();      
  let history = useHistory(); 
  const tamMax=150
  const auxCol=process.env.REACT_APP_Fondo_Color  
  const auxDispV =obtenDispo()  
  const [datos,setDatos] = useState([]);     
  const source = axios.CancelToken.source(); 
  const [totalGen,setTotalGen] = useState([]);     
  const [registro, setRegistro] = useState([]) 
  const [espera, setEspera] = useState(false) 
  const [listaExcel, setListaExcel] = useState([]);
  const [totalPaginas,setTotalPaginas]=useState(0);
  const [numPagina,setNumPagina]=useState(0);
  const [listPagina,setListPagina]=useState([]);
  
  useEffect(()=>{    
    //console.log(auxSecc);
    getList(auxSecc.SecId)
 
       
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])          

  const exportarArch = () => {
    let today = new Date();                    
    let fec = moment(today);        
    //console.log(listaExcel);
    const ws = XLSX.utils.aoa_to_sheet(listaExcel);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Reporte");
    XLSX.writeFile(wb,`${fec.format("YYMMDDHHmmss")} Lista de Avance Seccion ${auxSecc.SecNum}.xlsx` )
  }; 

  const getList = (auxSecc) => {
    const idusu = localStorage.getItem("UsuId")?parseInt(localStorage.getItem("UsuId")):0;    
    const fInicio=moment(fechas.inicio).format("YYYY-MM-DD");
    const fFinal=moment(fechas.final).format("YYYY-MM-DD");   
    const data = qs.stringify({   
        usuario:idusu,
        seccion:auxSecc,
        fini:fInicio+'T00:00:00',
        ffin:fFinal+'T23:59:59',
        idDispositivo: auxDispV,
    });
    const url = "/perfil/v2/avance/seccion/usuario";
  
    function respuesta(aux) {
      if (aux && aux.length!==0) 
      {
        let total=0
        const arrM=[]
        arrM.push(["#","Total", "Nombre"])
        aux.forEach((element,index) => {
            total+=element.total
            arrM.push([index+1,element.total, element.usuario])
        });
        setDatos(aux)  
        setListaExcel(arrM)
        numeroPag(aux,tamMax,setTotalPaginas,setNumPagina,setListPagina)    
        setTotalGen({Total:total})
      } 
      else{
        setListaExcel([])
        setDatos([])
        setListPagina([])
      }      
    }
  
    llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEspera, history, source);
  }; 

  const onChangeTable =(regis)=>{       
    setRegistro(regis)                           
  }

  const celda = (alinear, estilo, contenido,index) => {
    return (
    <TableCell align={alinear} key={index} className={classes.tableCell} 
      padding={'none'} style={estilo} >
      {contenido}
    </TableCell>)
  }

  const CelTitl=({index,titulo,aline,estilo})=> celda(aline?aline:"center", {...estilo,backgroundColor:auxCol,color:'white',}, titulo?titulo:"",index) 

  function onChangePagina(event, newPage){
    event.preventDefault();
    if (newPage ===0) 
    {
      setListPagina(datos.slice(0,tamMax))   
    }
    else
    {
      setListPagina(datos.slice(newPage * tamMax, newPage * tamMax + tamMax))   
    }
    setNumPagina(newPage)
  }

  function generarFooter(){
    return(
    <TablePagination 
      rowsPerPageOptions={false}  style={{padding:0}}
      rowsPerPage={tamMax} count={datos.length}
      page={numPagina} lang='es'
      labelDisplayedRows={
          ({ from, to, count }) => {
            return '' + from + '-' + to + ' de ' + count
          }
        }
      onPageChange={onChangePagina}
      ActionsComponent={PaginacionTab}/>)
  }

  function TablaEstr(auxDatos) {        
    
    return(        
    <TableContainer className={classes.tablaContainer} id="tamTablaReg"  >
    <Table stickyHeader aria-label="sticky table">
      <TableHead >    
        { totalGen.length!==0 ?
        <TableRow>
    
      
          <CelTitl index={3} titulo={  <IconButton onClick={exportarArch} size="small" >
            <Tooltip title="Exportar a Excel">
              <SaveIcon style={{color:'white'}} fontSize="small" />
            </Tooltip>
            </IconButton>} aline={"center"} estilo={{fontSize:"13px"}}  />   
          <CelTitl index={2} titulo={<span>Total <br/> <strong>
            <NumberFormat value={totalGen.Total} displayType={'text'}  thousandSeparator={true}/></strong></span> } aline={"center"} />   
          <CelTitl index={1} titulo={ "Usuario" } aline={"left"} estilo={{fontSize:"13px"}} />                         
        </TableRow>
        :null }                   
      </TableHead>  
      <TableBody>         
      {auxDatos.map((elemnto,index) => {  
        const auxVal=registro?.MunId === elemnto.MunId ?false:false                                              
        return (                  
        <TableRow className={classes.tableRow} selected={ auxVal} 
          classes={{ hover: classes.hover, selected: classes.selected }} 
          key={index+1} onClick={ () => onChangeTable(elemnto) }  
        >   
          {celda("left", {width:"13px"}, "",index+3) }     
          {celda("center", {width:"10rem"},<NumberFormat value={ elemnto.total} displayType={'text'}  thousandSeparator={true}/> ,index+2) }
          {celda("left", {width:"30rem"}, elemnto.usuario ,index+1) }        
        </TableRow>              
        );                        
      })}                                                
      </TableBody>
    </Table>
    </TableContainer>        
    ); 
  }
                       
  const llamada= listPagina.length !== 0 ?(TablaEstr(listPagina)):(<Box style={{paddingTop:1+'rem'}}>
                                  <Typography variant="h5" component="h5">No contiene datos</Typography>
                                  </Box>)
  const tabla = espera ?  (<Box pt={3}><LinearProgress/> </Box>) :( llamada )                                  
  const paginacion = !espera&&listPagina.length!==0 &&totalPaginas>1 ? generarFooter() : null;                                                                                                                                          
    return (
    <ModalInformativo   titulo="Busqueda de Sección"
        modalAbierto={modalAbierto}
        setModalAbierto={setModalAbierto}
        tamanio="sm"
    >
        <Box display={'flex'} flexDirection="row" style={{height: '29rem' }}>
            
          <Card className={classes.estiloCard} style={{height:window.innerHeight-140}}>  
            {paginacion}                      
            {/* <Typography variant="body1" component="body1"></Typography>  */}
            {tabla}   
          </Card>

        </Box>                    
    </ModalInformativo>
  );
}