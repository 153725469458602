import {makeStyles} from "@material-ui/core/styles";
import Modal from "../generales/ModalCerrarActualiza";
import {useEffect, useState} from "react";
import {FormControl, Grid, InputLabel, LinearProgress, MenuItem, Select, TextField} from "@material-ui/core";
import qs from "qs";
import {ErrorGeneral, llamaApiCargaToken,} from "../funciones/ClienteAxios";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment/moment";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import esLocale from "date-fns/locale/es";
import {toast} from "react-toastify";
import SelectEdo from '../generales/SelectEdo';

const useStyles = makeStyles((theme) => ({}));

const ModalAgregarRecorrido = ({abrirModal, setAbrirModal, idusu, history, source, latitud, longitud,actualiza}) => {

    const classes = useStyles();
    let usuTipo = localStorage.getItem('Tipo');
    let fecha = Date.now()
    const [selectedDate, handleDateChange] = useState(fecha);
    const [listMun, setListMun] = useState([])
    const [listSecc, setListSecc] = useState([])
    const [auxListSecc, setAuxListSecc] = useState([])
    const [listTipo, setListTipo] = useState([])
    const [nuevoRegistro, setNuevoRegistro] = useState({
        Estado: process.env.REACT_APP_EDO,
        Municipio: '',
        Seccion: '',
        Tipo: '',
        Latitud: latitud,
        Longitud: longitud,
        Fecha: '',
        Direccion: '',
        Observacion: ''
    });
    const {Estado, Municipio, Seccion, Tipo, Longitud, Latitud, Fecha, Direccion, Observacion} = nuevoRegistro
    const [btnDeshabilitado, setBotonDeshabilitado] = useState(false)
    const [aviso, setAviso] = useState(false);
    const [mensaje, setMensaje] = useState("");
    const [tipoAdvertencia, setTipoAdvertencia] = useState("success");
    const [cargando, setCargando] = useState(false);

    const handleClose = () => {
        setAbrirModal(false)
        setAviso(false);
    }

    useEffect(() => {
       

        const recuperarSecciones = () => {
            let data = qs.stringify({
                usuario: idusu,
                idDispositivo: 'w8rf51v21dsd2cs',
            });
            let url = "lugar-seccion-list";

            function respuesta(response) {
                if (response[0].Id != -1) {
                    setListSecc(response)
                }
            }

            llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargando, history, source);
        };

        const recuperarTipo = () => {
            let data = qs.stringify({
                Usuario: idusu,
                idDispositivo: '45vf2d1vdf21d2f'
            });
            let url = "recorrido-tipo";

            function respuesta(response) {
                if (response[0].Id != -1) {
                    setListTipo(response)
                }
            }

            llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargando, history, source);
        };

        if (listMun.length === 0) {
            recuperarMunicipios(process.env.REACT_APP_EDO)
        }
        if (listSecc.length === 0) {
            recuperarSecciones()
        }
        if (listSecc.length === 0) {
            recuperarTipo()
        }
    }, []);

    const recuperarMunicipios = (estado) => {
        let data = qs.stringify({
            usuario: idusu,
            estado: estado,
            tipo: usuTipo,
            idDispositivo: 'w8rf51v21dsd2cs',
        });
        let url = "lugar-municipio-tipo";

        function respuesta(response) {
            if (response[0].Id != -1) {
                setListMun(response)
            }
        }

        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargando, history, source);
    };

    const guardarMuni = (e) => {
        if (e !== null && e !== undefined) {
            let seccFilt = listSecc.filter((auxSeccion) => auxSeccion.MuniId === e.Id)
            setAuxListSecc(seccFilt)
            setNuevoRegistro({
                ...nuevoRegistro,
                Municipio: e,
                Seccion: ""
            })
            setMensaje('');
            setAviso(false);
        } else {
            setNuevoRegistro({
                ...nuevoRegistro,
                Municipio: "", Seccion: ""
            })
            setAuxListSecc([])
        }
    }

    const guardarSeccionSelect = (e) => {
        if (e !== null && e !== undefined) {
            setNuevoRegistro({
                ...nuevoRegistro,
                Seccion: e.target.value
            })
            setMensaje('');
            setAviso(false);
        } else {
            setNuevoRegistro({
                ...nuevoRegistro,
                Seccion: ""
            })
        }
    }

    const onChange = e => {
        setNuevoRegistro({...nuevoRegistro,[e.target.name]: e.target.value})
    }

    const onChangeEdo = (e) => {
        if (e !== null && e !== undefined) {
            setNuevoRegistro({
                ...nuevoRegistro,
                Estado: e.target.value,
                Municipio: "", Seccion: ""
            })
            setMensaje('');
            setAviso(false);
        } else {
            setNuevoRegistro({
                ...nuevoRegistro,
                Estado: "",
                Municipio: "", Seccion: ""
            })
        }
    }

    const guardarRecorrido = () => {
        setCargando(true)
       // setBotonDeshabilitado(true);

        let data = qs.stringify({
            Usuario: idusu,
            Edo: Estado,
            Muni: Municipio.Id,
            Secc: Seccion,
            fecha: moment(selectedDate).format("YYYY-MM-DD") + 'T00:00:00',
            latitud: Latitud,
            longitud: Longitud,
            Tipo: Tipo,
            Direccion: Direccion,
            Observ: Observacion,
            idDispositivo: '45vf2d1vdf21d2f'
        });

        let url = "recorrido-nuevo";

        function respuesta(response) {
            if (response.respuesta === 1) {
                setAviso(true)
                toast.success('Recorrido agregado correctamente', {
                    pauseOnFocusLoss: false,
                    toastId: `nvo-recorrido${1}`
                })
                actualiza()
                setAbrirModal(false)
            } else {
               // setBotonDeshabilitado(false);
                toast.error('Datos incorrectos, intente mas tarde', {
                    pauseOnFocusLoss: false,
                    toastId: `error-recorrido${1}`
                })
            }
        }

        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargando, history, source);
    }

    const verificaNulos = (campo,descripcion)=>{
        let respuesta =false;        
          
        if( (typeof campo !== 'number' && typeof campo === 'string' 
            && (campo.trim() === '' || campo.trim() === null) )
        ) 
        {   respuesta=true;        
          //  console.log(campo+' '+descripcion);                  
            setMensaje('No se lleno el campo '+descripcion);
            setTipoAdvertencia("warning")  
            setAviso(respuesta);
        }
        return(respuesta);
    }

    function guardarDatos(e) {
        e.preventDefault()
        if ( !verificaNulos(Municipio,"Municipio") && !verificaNulos(Seccion,"Sección")  && 
            !verificaNulos(Tipo,"Tipo") && !verificaNulos(Direccion,"Dirección") ) 
        {   //console.log("prueba",nuevoRegistro);
            setAviso(false);
            guardarRecorrido()
           // console.log( moment(selectedDate).format("YYYY-MM-DD") + 'T00:00:00');
        }  
    }

    return (
    <Modal
        titulo={'Nuevo Registro'} modalAbierto={abrirModal} mensaje={mensaje}
        setModalAbierto={setAbrirModal} guardarDatos={guardarDatos}
        tipoAdvertencia={tipoAdvertencia} aviso={aviso} tamanio={'sm'}
        btnDeshabilitado={btnDeshabilitado} setAviso={setAviso}
        cargando={cargando} cerrarBorrar={handleClose}
    >
        <Grid container alignItems={'center'} spacing={1}>
            <Grid item xs={5}>
            
                <SelectEdo edo={Estado} onChangeSlct={onChangeEdo} tipo="outlined" />
               {/*  <FormControl size="small">
                    <InputLabel htmlFor="Estado">Estado</InputLabel>
                    <Select native value={Estado} disabled fullWidth                        
                        inputProps={{name: 'Estado', id: 'Estado',}}
                        onChange={onChange}
                    >
                        <option value={process.env.REACT_APP_EDO}>Puebla</option>
                    </Select>
                </FormControl> */}
            </Grid>
            <Grid item xs={7}>
                {listMun.length !== 0 ?
                    <Autocomplete
                        id="muni-select-nvoPerfil" size='small' fullWidth
                        onChange={(e, option) => guardarMuni(option)} value={Municipio}
                        getOptionLabel={(option) => option.Nom} name="Municipio"
                        noOptionsText={"Sin coincidencias"} options={listMun}
                        renderInput={(params) => (
                            <TextField {...params} size='small'
                                label="Municipio" variant="outlined"/>
                        )}
                    />
                    : <LinearProgress/>}
            </Grid>
            <Grid item xs={4}>
                {auxListSecc.length !== 0 ?
                    <FormControl size='small'>
                        <InputLabel size='small' variant="outlined" id="Seccion-label-nvo">Sección</InputLabel>
                        <Select size='small' variant="outlined" style={{width: '7rem'}}
                            label="Sección" id="Seccion-select2" name="Seccion"
                            value={Seccion} onChange={guardarSeccionSelect}
                            fullWidth
                        >
                            {auxListSecc.map((seccion, index) => {
                                return (
                                <MenuItem size='small' value={seccion.Id} key={seccion.Id}>
                                    <em>{seccion.Numero}</em>
                                </MenuItem>
                                )
                                }
                            )}
                        </Select>
                    </FormControl>
                    : <FormControl size='small'>
                        <InputLabel size='small' variant="outlined" id="Seccion-label-nvo">Sección</InputLabel>
                        <Select size='small' variant="outlined" style={{width: '7rem'}} 
                            label="Sección" id="Seccion-select2" name="Seccion"
                            value={Seccion} onChange={guardarSeccionSelect}
                            fullWidth
                        >
                            <MenuItem size='small' value={''}>
                                <em></em>
                            </MenuItem>
                        </Select>
                    </FormControl>
                }
            </Grid>
            <Grid item xs={4}>
                {listTipo.length !== 0 ?
                <FormControl size="small">
                    <InputLabel htmlFor="Tipo">Tipo</InputLabel>
                    <Select native value={Tipo} fullWidth onChange={onChange}
                        inputProps={{name: 'Tipo', id: 'Tipo',}}
                    >
                        {listTipo.map(element => {
                            return <option key={element.Id} value={element.Id}>
                                {element.Nom}</option>
                        })}
                    </Select>
                </FormControl>
                : null
                }
            </Grid>
            <Grid item xs={4}>
                <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
                    <DatePicker
                        disableFuture label="Fecha"
                        format="dd/MM/yyyy" size="small"
                        views={["year", "month", "date"]}                                                
                        minDate="2017-03-14" maxDate={new Date()}
                        value={selectedDate} onChange={handleDateChange}
                        style={{width: 6.5 + 'rem'}}
                    />
                </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} style={{marginBottom:.5+'rem',marginTop:.5+'rem'}}>
                <TextField id="Direccion" name="Direccion" label="Dirección" fullWidth size="small"
                    value={Direccion} onChange={onChange} variant="outlined"/>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    id="Observacion" name="Observacion"
                    label="Observación" variant="outlined"
                    multiline fullWidth onChange={onChange}
                    rows={4} value={Observacion}
                />
            </Grid>
        </Grid>
    </Modal>);
};

export default ModalAgregarRecorrido;
