import React from 'react';
import {BrowserRouter as Router,Switch,Route} from 'react-router-dom';
import {ThemeProvider} from '@material-ui/core/styles'
import {generarTema} from './componentes/layout/Tema'
import AuthProvider  from './componentes/context/AuthContext';
import GeneralesProvider from './componentes/context/GeneralesContext';
import ClienteProvider from './componentes/context/ClienteContext';
import Contenedor from './componentes/layout/Contenedor'
import Login from './componentes/auth/Login'
import { ToastContainer } from 'react-toastify';

import Recorrido from './componentes/Recorrido'
import MapaSecciones from './componentes/mapa'
import AvanceGeneral  from './componentes/AvGeneral';
import LimpiaCache from './componentes/LimpiaCache';
import 'react-toastify/dist/ReactToastify.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import Evento from './componentes/Evento';
function App() {
  const [colorTema,setColorTema] = React.useState(false);
  React.useEffect(()=>{
    const auxColor= localStorage.getItem('ColorTheme');
    if ( auxColor===undefined || auxColor===null) 
    {
      localStorage.setItem('ColorTheme',colorTema);
    //  console.log("sin el tema");
    }
    else
    {  //console.log("cargando el tema");
      //console.log(JSON.parse(auxColor));
      setColorTema(JSON.parse(auxColor))    
    }
  },[])

  return (
  <React.Fragment >
    <AuthProvider>
      <GeneralesProvider>
        <ClienteProvider>
        <Router>
          <ThemeProvider theme={generarTema(colorTema)}>
            <Contenedor colorTema={colorTema} setColorTema={setColorTema}>              
              <Switch>
                <Route exact path="/" component={Login}  />                          
                <Route exact path="/evento" component={Evento}  />  
                <Route exact path="/recorrido" component={Recorrido}  />                
                <Route exact path="/mapa-secc" component={MapaSecciones}  />                
                <Route exact path="/avance-general" component={AvanceGeneral}  />                 
                <Route exact path="/limpiar-cache" component={LimpiaCache}  />                                 
               {/* */}
                <Route path="*" component={  Login } />
              </Switch>              
            </Contenedor>
          </ThemeProvider>
          <ToastContainer autoClose={5000} closeOnClick/>
        </Router>
        </ClienteProvider>
      </GeneralesProvider>
    </AuthProvider>
  </React.Fragment>
  );
}

export default App;