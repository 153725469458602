import React,{useState,useEffect} from 'react';
import {Box, LinearProgress,Select,FormControl,InputLabel,
    MenuItem, Card,Paper,Typography,
    Button
} from "@material-ui/core";
import TablaEvento from './TablaEvento';
import axios from "axios";
import qs from "qs";
import { useHistory } from "react-router-dom";
import IntervaloSelect from './IntervaloTabla';
import IconBuscar from '../generales/Iconos/IconBuscar';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import {llamadaApiToken,llamaApiCargaToken,ErrorGeneral } from '../funciones/ClienteAxios';
import {GeneralesContext} from '../context/GeneralesContext'
import { obtenDispo } from '../funciones/IdDispositivo';
import TablaTotalAsistencia from './TabTotalAsis';
import TablaTotalRegistros from './TabTotalReg';
import TablaAsistencia from './TabAsistencia';
import TablaTotalEdad from './TabTotalEdad';
import Viewer from 'react-viewer';
import GraficaEdad from './GraficaPolar';
import Mapa from './Mapa';
import io from "socket.io-client";
import {toast} from "react-toastify";
import moment from "moment";
const ENDPOINT = "https://metepec-notificacion.daduva.mx:5012";

const Evento = () => {
    let history = useHistory();         
    const auxDispV =obtenDispo()      
    const source = axios.CancelToken.source();
    const socket = io(ENDPOINT,  {transports: ['websocket']});//se conecta   
    //let token =localStorage.getItem('token20') ;  
    const usuId =localStorage.getItem('UsuId') ;     
    const cadena = localStorage.getItem("Operacion")+"|"; 
    const {generales,guardarGenerales}=React.useContext(GeneralesContext);                    
    const [listEvento, setListEvento] = useState([])
    const [listMapSec, setListMapSec] = useState([])
    const [listTipo, setListTipo] = useState([])
    const [listAsistencia, setListAsistencia] = useState([])
    const [evento, setEvento] = useState("")
    const[listExcel, setListExcel] = useState([])  
    const[listEventSlct, setListEventSlct] = useState([])  
    const [fSelect, setFSelect] = useState({inicio:new Date(),final:new Date()});    
    const [filtro, setFiltro] = useState({Nombre:"", Municipio:0, Seccion:"",tipoDet:"", Descr:""})
    const [espera, setEspera] = useState(false)
    const [espera2, setEspera2] = useState(false)
    const [espera3, setEspera3] = useState(false)
    const [actualizar, setActualizar] = useState(false)
    const [registroEvent, setRegistroEvent] = useState([])
    const [visible, setVisible] = useState(false);
    const [centro, setCentro] = useState({lat: 19.493815, lng: -99.1137317})    
    const {Nombre, Municipio, Seccion,tipoDet, Descr}= filtro

    useEffect(() => {                
                
        if (usuId !== null && usuId !== undefined  ) 
        {
            guardarGenerales({...generales,Titulo:'Eventos'})            
        }
        else
        {   
            localStorage.clear();                            
            history.push("/");  
        }
        getListEven()
        llenaLisEvento()
        
        return ()=> {
            source.cancel();
        }  
    }, [])

    useEffect(() => {                        
        llenaListSug(evento)
        getListMapSec(evento)
    }, [actualizar])

    useEffect(() => {       
        if (registroEvent.length!==0) {
            getListConsAsistencia(registroEvent)    
        }                 
        
    }, [registroEvent])

    useEffect(()=>{    
    
        socket.on("NotifNewEmit", data => {                
            const info=JSON.parse(data)            
            if ( 0 !== parseInt(info.Key )) 
            {
                llenaListSug(0)
                const auxId=parseInt(info.Evento)
                if (registroEvent.Id===auxId) {
                    getListConsAsistencia({Id: auxId})    
                }
                
                const fch= moment(info.Fecha).format("YYYY-MM-DD hh:mm a")
                let mensaje=""
                if (info.Tipo==="Registro") {
                    mensaje=`Nuevo registro, ${fch}`     
                }
                else{
                    if (info.Tipo==="Asistencia") {
                        mensaje=`Nueva Asistencia, ${fch}`     
                    }
                }
                
                toast.info(mensaje, {
                    pauseOnFocusLoss: false,
                    toastId: `socket-notifi${info.Key}`
                })
            }            
        });       

        return () => socket.disconnect();   
    })
     
    const getListEven = ( ) => {
        const data = qs.stringify({
    
            idDispositivo: auxDispV,
        });
        const url = "evento/v1/list";

        function respuesta(response) {
            if (response[0].Id !== -1) {
               
                setListEventSlct(response)
            }
            else{
                setListEventSlct([])
            }
        }
    
        llamadaApiToken(data, url, respuesta, ErrorGeneral, history, source);
    };


    const getListMapSec = (auxEvento) => {
        
        const data = qs.stringify({
            Usuario:usuId,            
            Evento:auxEvento !== "" ? auxEvento :0,
            idDispositivo: auxDispV,
        });
        const url = "evento/v1/avance/secc";
        function respuestaListSug(auxiliar) {
            if (auxiliar.length!==0 && auxiliar[0].secciones   ) {
         
                
                setListMapSec(auxiliar[0]);   
                setCentro({lat: auxiliar[0].secciones[0].gps[0].Lat,
                     lng: auxiliar[0].secciones[0].gps[0].Lon})   
                //console.log(auxiliar[0]);
                
            }else{
                setListMapSec([]);      
            }
        }
    
        llamaApiCargaToken(data, url, respuestaListSug,ErrorGeneral,setEspera3,history,source);
    };

    const llenaListSug = (auxEvento) => {
        
        const data = qs.stringify({
            Usuario:usuId,            
            Evento:auxEvento !== "" ? auxEvento :0,
            idDispositivo: auxDispV,
        });
        const url = "evento/v1/avance";
        function respuestaListSug(auxiliar) {
            if (auxiliar[0].Id !== -1) {
         
                
                setListEvento(auxiliar);      
            }else{
                setListEvento([]);      
            }
        }
    
        llamaApiCargaToken(data, url, respuestaListSug,ErrorGeneral,setEspera,history,source);
    };


    const llenaLisEvento = (auxEvento) => {
        
        const data = qs.stringify({  
            Usuario:usuId,            
            Evento:auxEvento !== "" ? auxEvento :0,          
            idDispositivo: auxDispV,
        });
        const url = "evento/v1/list";
        function respuestaListSug(auxiliar) {
            if (auxiliar[0].Id !== -1) {
         
                
                setListTipo(auxiliar);      
            }else{
                setListTipo([]);      
            }
        }
    
        llamaApiCargaToken(data, url, respuestaListSug,ErrorGeneral,setEspera,history,source);
    };

    const getListConsAsistencia = (auxEvento) => {
        
        const data = qs.stringify({    
            Usuario:usuId,            
            Evento:auxEvento.Id ,           
            idDispositivo: auxDispV,
        });
        const url = "evento/asistencia/v1/list";
        function respuestaListSug(auxiliar) {
            if (auxiliar[0].Id !== -1) {
         
                
                setListAsistencia(auxiliar);      
            }else{
                setListAsistencia([]);      
            }
        }
    
        llamaApiCargaToken(data, url, respuestaListSug, ErrorGeneral, setEspera2, history, source);
    };
    
    
    const btnBuscar=(e)=>{
        e.preventDefault()
       // llenaListSug()
       setRegistroEvent([])
       setListAsistencia([])
       setActualizar(!actualizar)
    }           

    
    const guardarEvento = (e) => {
        if (e !== null && e !== undefined) {
            setEvento( e.target.value)
            
        } else {
            setEvento( "")
        }
    }

    const handleViewer = ( ) => {
        setVisible(true)        
    }
    
    const cardImg =(elemento)=>{
       return<Card>
            <Box display={"flex"} flexDirection={"column"}>
            <Typography style={{padding:".5rem"}}>
                {elemento.Inmueble}
                
            </Typography>
            <img src={elemento.Img } style={{width:"18.5rem"}}
                onClick={() => handleViewer( )}
            />
            <Typography variant='body' style={{width:"18rem", padding:".5rem"}}>
                {elemento.Dir}
            </Typography>
            </Box>
        </Card>
    }

    const viewer=(registro) =>{ 
        const galeria=[]
        galeria.push({Img: registro.Img, Nom: registro.Nom})
    return<Paper square>
    <Viewer
        visible={visible}
        onClose={() => {
            setVisible(false);
        }}
        images={galeria.map((element) => 
            ({src: element.Img, alt: element.Nom})
        )}    
        zIndex={2000}
    />
    </Paper>}

    return (
    <div style={{marginLeft:.5+'rem'}} >
        {registroEvent.length!==0?viewer(registroEvent):null }
        <Box display={'flex'} mt={1} mb={1}>
            <Box >
                <IconBuscar leyenda="Buscar" handleClick={(e)=>btnBuscar(e)} />                                                                                                                        
            </Box>    
            <Box>
               {listEventSlct.length !== 0 ? <FormControl size='small'>
                    <InputLabel size='small' variant="outlined" id="evento-label-nvo">Evento</InputLabel>
                    <Select size='small' variant="outlined" style={{width: '20rem'}}
                        label="Evento" id="Evento-select2" name="evento"
                        value={evento} onChange={guardarEvento}
                        fullWidth
                    >
                        <MenuItem value={0} >Todos</MenuItem>
                        {listEventSlct.map((element, index) => {
                            return (
                            <MenuItem size='small' value={element.Id} key={element.Id}>
                                <em>{element.Nom}</em>
                            </MenuItem>
                            )
                            }
                        )}
                    </Select>
                </FormControl>
                : null} 
            </Box>                                
        </Box>
        <Box display={"flex"} flexDirection={"row"}>
           
            {listEvento.length!==0 ?
                <GraficaEdad listEdad={listEvento[0].totalEdades}/>    
                :null
             }
            
            { !espera  ? 
                (listEvento.length !== 0 ?
                    <TablaEvento listSuge={listEvento[0].detalle}  listExcel={listExcel} 
                        registro={registroEvent} setRegistro={setRegistroEvent}
                    />
                    :null)
                :    <Box pt={3}><LinearProgress/> </Box>
            }
            {   registroEvent.length!==0?
                cardImg(registroEvent)
                :null
            }
        </Box>
        <Box display={"flex"} flexDirection={"row"} mt={1}>
       {listMapSec.length!==0?
       <Box  mr={1} >
        <Box display={"flex"} flexDirection={"row"} mb={1}>
        <Box display="flex"  alignItems="center"  ml={2}>
            <Typography variant="body1">Asistencia</Typography>
            <Brightness1Icon fontSize="small" style={{color: "#FFA318"}} />
        </Box>
        <Box display="flex"  alignItems="center"  ml={2}>
            <Typography variant="body1">Con Registros</Typography>
            <Brightness1Icon fontSize="small" style={{color: "#DBE772"}} />
        </Box>
        <Box display="flex"  alignItems="center"  ml={2}>
            <Typography variant="body1">Sin Registro</Typography>
            <Brightness1Icon fontSize="small" style={{color: "#98cfe3"}} />
        </Box>
        <Box ml={2}>
            <Button variant="contained" color="primary" size='small' onClick={()=>getListMapSec(evento)}>
                Actualizar Mapa
            </Button>
        </Box>
        </Box>
        {!espera3 ? 
        <Mapa poligonos={[]} centro={centro}  auxTam={{wd:600, hgt:400}}  
            setCambiaMarcador={()=>{}}  zoom={12.5}  listMapSec={listMapSec}           
        />
        :<Box width={"12rem"} mt={2}> <LinearProgress/></Box>}
        </Box>
        :null}        
        { registroEvent.length!==0 && registroEvent.registros ? 
            <TablaTotalRegistros listSuge={registroEvent.registros}  
                registro={[]} setRegistro={()=>{}}
            />
            :    null
        }
        { registroEvent.length!==0 &&  registroEvent.asistencia  ? 
            <TablaTotalAsistencia listSuge={registroEvent.asistencia}  
                
            />
            :    null
        }
        { registroEvent.length!==0 &&  registroEvent.edades  ? 
            <TablaTotalEdad listSuge={registroEvent.edades}                  
            />
            :    null
        }
        { registroEvent.length!==0 &&  listAsistencia.length!==0  ? 
            <TablaAsistencia listAsistencia={listAsistencia}  
                registro={[]} setRegistro={()=>{}}
            />
            :    null
        }   
        </Box>
    </div>
    );
};

export default Evento;