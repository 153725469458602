import React, { useState } from "react";
import {  //Box,LinearProgress,
  Typography, Table, TableBody,
  TableCell, TableContainer, TableHead,
  TableRow, Card, Tooltip,
  TablePagination,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import XLSX from 'xlsx';
import PaginacionTab from "../layout/PaginacionTab";
import {numeroPag} from "../funciones/Paginacion";
import {findNumberCad} from '../funciones/FindNumber'
import SaveIcon from '@material-ui/icons/Save';
const useStyles = makeStyles({
  
  container: {
    //maxHeight: 550,
    maxHeight: window.innerHeight - 250,
    minHeight: 280,
    width: "14 rem",
  },
  container2: {
    maxHeight: 650,
    //maxHeight: window.innerHeight-170,
    //minHeight: 280,
  },
  tableRow: {
    "&$selected, &$selected:hover": {
      backgroundColor: process.env.REACT_APP_Color_Second,
    },
  },
  tableCell: {
    "$selected &": {
      color: "white",
    },
  },
  hover: {},
  selected: {},
});

const columnas = [
  { id: 1, titulo: "Fecha", alinear: "center", },  
  { id: 5, titulo: "Total x Dia",alinear: "center",  },  
  { id: 13, titulo: "Registros",alinear: "center",  },    
];

const TablaTotalRegistros = ({  listSuge,}) => {
  const classes = useStyles();
  let history = useHistory();
  const source = axios.CancelToken.source();
  const cadena = localStorage.getItem("Operacion")+"|"; 
  const fondo =process.env.REACT_APP_Fondo_Color
  const tamMax = 100
  const [datos, guardarDatos] = useState([]);
  const [sinDatos, guardarSinDatos] = useState(true);
  const [espera, setEspera] = useState(false);
  const [ registro,setRegistro] = useState([]);
  const [totalPaginas, setTotalPaginas] = useState(0);
  const [numPagina, setNumPagina] = useState(0);
  
  React.useEffect(() => {
    
    if (listSuge.length!==0  ) {
      numeroPag(listSuge, tamMax, setTotalPaginas, setNumPagina, guardarDatos)
      //guardarDatos(listSuge);  
    }else{
      guardarDatos([]);
    }

    return () => { source.cancel() };
  }, [listSuge]);

  const seleccionado = (auxId) => {
    setRegistro(auxId);
  };

  const celda = (alinear, estilo, contenido) => {
    return (
    <TableCell align={alinear} className={classes.tableCell}
      padding={"none"} style={estilo}
    >
      {contenido}
    </TableCell>
    );
  };
 

  function Renglon(props) {
    const { row } = props;
    const auxFReg =
      moment.utc(row.Fch).format("YYYY-MM-DD HH:mm") === "1900-01-01 00:00"
        ? ""
        : moment.utc(row.Fch).format("DD/MM/YYYY HH:mm");
    return (    
    <TableRow
      onClick={() => { seleccionado( row) }}
      className={classes.tableRow} selected={registro?.Dia === row.Dia}
      classes={{ hover: classes.hover, selected: classes.selected }}
    >
      {celda("center", { width: "80px", fontSize: "12px", paddingRight: "2px" }, `${row.Dia}/${row.Mes}/${row.Anio}` )}       
      {celda( "center", { width: 100, fontSize: "12px", paddingLeft: "8px" }, row.Total)}                  
      {celda( "center", { width: 100, fontSize: "12px", paddingLeft: "8px" }, row.Persona)}
       
    </TableRow>  
    );
  }

  const vacia = (
    <TableCell className={classes.tableCell} align="center">
      {" "}
    </TableCell>
  );
  const ColumnaVacia = (
    <TableCell
      className={classes.tableCell}
      align="center"
      style={{ backgroundColor: fondo, color: "white" }}
    >
      {" "}
    </TableCell>
  );

  function tablaCompleta(auxlista) {
    return (
    <TableContainer className={classes.container}>
    <Table stickyHeader aria-label="sticky table">
      <TableHead>
        <TableRow>                     
          {columnas.map((row, index) => {
            const auxRow= index ===0? `${auxlista.length} ${row.titulo}` :row.titulo
            return (
            <TableCell
              style={{ backgroundColor: fondo, color: "white" }}
              key={row.titulo + row.id}  align={row.alinear}
              padding={"none"}
            >
              {auxRow}
            </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {auxlista.length != 0
          ? auxlista.map((row) => (
              <Renglon key={"regPerfilGen" + row.Id} row={row} />
            ))
          : null}
      </TableBody>
    </Table>
    </TableContainer>
    );
  }

  function onChangePagina(event, newPage) {
    event.preventDefault();
    if (newPage === 0) {
        guardarDatos(listSuge.slice(0, tamMax))
    } else {
      guardarDatos(listSuge.slice(newPage * tamMax, newPage * tamMax + tamMax))
    }
    setNumPagina(newPage)
  }

  function generarFooter() {
    return (
    <TablePagination style={{padding:0}}
      rowsPerPageOptions={false} colSpan={3}
      rowsPerPage={tamMax} count={listSuge.length}
      page={numPagina} lang='es'
      labelDisplayedRows={
        ({from, to, count}) => {
          return '' + from + '-' + to + ' de ' + count
        }
      }
       onPageChange={onChangePagina}
      ActionsComponent={PaginacionTab}/>)
  }

  const tablaSinDatos = () => {
    return (
    <TableContainer className={classes.container2}>
    <Table stickyHeader aria-label="sticky table">
      <TableHead>
        <TableRow>              
          {columnas.map((row) => {
            return (
              <TableCell
                style={{ backgroundColor:fondo, color: "white" }}
                key={row.titulo + row.id}
                align={row.alinear}
                padding={"none"}
              >
                {row.titulo}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow className={classes.tableRow}>
      
          <TableCell className={classes.tableCell} align="center">
            Sin{" "}
          </TableCell>
          {vacia}
        
          <TableCell className={classes.tableCell} align="center">
            Registros
          </TableCell>
          {vacia}
          {vacia}  
         
        </TableRow>
      </TableBody>
    </Table>
    </TableContainer>
    );
  };

  const contenido = datos.length !== 0 ? tablaCompleta(datos) : tablaSinDatos();
  //const tabla = espera ?  (<Box pt={3}><LinearProgress/> </Box>) :( contenido )  

  const paginacion = !espera && datos.length !== 0 && totalPaginas > 1 ? generarFooter() : null;
  return (
  <div style={{ minWidth: 13 + "rem", maxWidth: 14 + "rem", marginRight:"1rem"}}>
    <Card>
    <Typography>Total Registro</Typography>
    {paginacion}
    {contenido}
    </Card>
  </div>
  );
};

export default TablaTotalRegistros;
