import React,{useState,useEffect} from 'react';
import { GoogleMap, LoadScript,Polygon,Marker } from '@react-google-maps/api';
import {generaCoordenadaDinam} from '../funciones/Mapas/FormatoCoord'
import { Typography ,Box, Paper, Card, IconButton} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CajaLeyenda from './CajaLeyenda';
  let arreColores=["#038545", "#EF8012", "#347AF4", "#FFC107", "#119A4F", "#B406D8", "#FFA318", "#DC4A3D", "#32CA81", 
                    "#F9B546", "#ED0808", "#0097A7", "#880E4F", "#4BAC7D", "#C52363", "#F57F06", "#7C594C", "#DBE772"]

export default function Mapa({poligonos, centro, setCambiaMarcador, redimensionar, zoom, auxTam, listMapSec  }){  
  let altuTamMax=825
  let anchoTamMax=1535
  const [rutaPol, setRutaPol] = useState([]);  
  const [galeria, setGaleria] = useState([]);  
  const [auxColores, setAuxColores] = useState(arreColores);
  const [alturaContenedor, setAlturaContenedor] = useState (
                                            (!redimensionar) ? (window.B406D<altuTamMax ? window.innerHeight-165: window.innerHeight - 200)
                                                             : (window.innerHeight<altuTamMax ? window.innerHeight-215: window.innerHeight - 250))
  const [anchoContenedor, setAnchoContenedor] = useState(
                                            (!redimensionar) ? window.innerWidth<anchoTamMax ? window.innerWidth-200 : window.innerWidth-300
                                                             : window.innerWidth<anchoTamMax ? window.innerWidth-510 : window.innerWidth-750)  
  const [leyendaMap, setLeyendaMap] = useState([]);
  const [listRegSecc, setListRegSecc] = useState([]);
  const [listRegAsist, setListRegAsist] = useState([]);
  useEffect(()=>
  {  
    if (listMapSec.detalle&&listMapSec.detalle.length!==0 ) {
      let auxFiltro=[],auxFiltro2=[]
      listMapSec.detalle.forEach((elemento)=>{
        if (elemento.registros) {
          for (let index = 0; index < elemento.registros.length; index++) {
            const element = elemento.registros[index];
            auxFiltro.push(element)  
          }
        }      
        if (elemento.asistencia) {
          for (let index = 0; index < elemento.asistencia.length; index++) {
            const element = elemento.asistencia[index];
            auxFiltro2.push(element)  
          }
        }        
      })      
      setListRegAsist(auxFiltro2)
      setListRegSecc(auxFiltro)
    }
    
 

    if (listMapSec.secciones&&listMapSec.secciones.length!==0)
    {      
      let ordenado=[]
      listMapSec.secciones.forEach((poligono) => {
        let arr=[]
        if (poligono.gps) {
          poligono.gps.forEach((coorde)=>{
            let auxCoord=generaCoordenadaDinam(coorde.Lat, coorde.Lon) 
            arr.push(auxCoord)                 
          })
          const auxCentro=generaCoordenadaDinam(poligono.Lat, poligono.Lon) 
          ordenado.push({Id:poligono.Id,centro:auxCentro,secc:poligono.Num,
            gps:arr,Color:poligono.Color?poligono.Color:""})  
        }
        
      })

      setRutaPol(ordenado);

      let auxNumSecc = listMapSec.secciones.length
      if (auxNumSecc > arreColores.length) 
      {
        let auxArregloColor=[]

        for (let index = 0; index < auxNumSecc; index++) 
        { let nivel= (index % 18 === 0) ? (index / 18===1 ? 0 :17): (index % 18)
          auxArregloColor[index] = arreColores[nivel]          
        } 
        setAuxColores(auxArregloColor)
      }      
    
    }


    setCambiaMarcador(true)      
    
  },[centro,poligonos]);


useEffect(()=>{
  window.addEventListener('resize', function() {
    if(!redimensionar){
        if(window.innerHeight<altuTamMax){
          setAlturaContenedor(window.innerHeight-165)
          }else{
              setAlturaContenedor(window.innerHeight-200)
          }
          if(window.innerWidth<anchoTamMax){
            setAnchoContenedor(window.innerWidth-200)
          }else{
            setAnchoContenedor(window.innerWidth-200)
          }
    }else{
      if(window.innerHeight<altuTamMax){
            setAlturaContenedor(window.innerHeight-200)
        }else{
            setAlturaContenedor(window.innerHeight-300)
        }

        if(window.innerWidth<anchoTamMax){
            setAnchoContenedor(window.innerWidth-510)
        }else{
            setAnchoContenedor(window.innerWidth-750)
        }
    }
});
},[alturaContenedor,anchoContenedor,anchoTamMax,altuTamMax,redimensionar])

 
  const options =(Color,numero)=> {    
    const auxiliar={  
    fillColor: Color.length!==0?Color: "#98cfe3",//auxColores[numero],
    fillOpacity: 0.5,
    strokeColor: "#0097A7",
    strokeOpacity: 1,
    strokeWeight: 2,
    clickable: false,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1}
    return auxiliar;
  }

  const formatoInfo=(info,)=>{    
    let auxArr=[]
    const txt=`Lugar: ${info.Inmueble}`
    const auxGale=[{src:info.Img,Descr: info.Inmueble}]      
    const txt2=`Dirección: ${info.Dir }`
    
    setGaleria(auxGale)
    auxArr={id:info.Id, texto:txt, texto2:txt2 }          
  
    setLeyendaMap(auxArr)
    //return auxArr
  }

  const onLoadPolygono = marker => {
    //console.log('Poligono: ', marker)
  }  

  return (    //"AIzaSyD0QUSpmbFDJLxSl5YXdmANyrA5GQoiWWE"
  <LoadScript googleMapsApiKey={process.env.REACT_APP_G_MAP_KEY} //"AIzaSyBhYo8BFHYfOSBLQgYVef1_MdWz_y_Z2Mg 
  libraries={["places"]}> 
    <GoogleMap
      id="searchbox-example" zoom={zoom} center={centro}
      mapContainerStyle={{ width:auxTam && auxTam.wd ?auxTam.wd : anchoContenedor, 
        height:auxTam && auxTam.hgt ?auxTam.hgt : alturaContenedor }}                
    >       
    {leyendaMap.length!==0?
      <CajaLeyenda leyendaMap={leyendaMap} setLeyendaMap={setLeyendaMap} galeria={galeria} />      
    :null }     

    {   listRegSecc.length!==0 && listRegAsist.length !== 0 && rutaPol!==null && rutaPol.length!==0 ?
      rutaPol.map((poligono,index) => 
      { let auxColor=""
      
        const filtro= listRegAsist.filter((regisAsis) => regisAsis.Num === poligono.secc)
        if (filtro.length!==0) {
          auxColor="#FFA318" 
        }else{
          const filtro2= listRegSecc.filter((regisSecc) => regisSecc.Num === poligono.secc)
          if (filtro2.length!==0) 
          { auxColor="#DBE772"        
            
          }
        }
        
        
        return(
        <Polygon
          onLoad={onLoadPolygono} paths={poligono.gps}
          options={options(auxColor, index)}
        />)
      })      
      :null
    }

    { rutaPol!==null && rutaPol.length!==0 ?
      listMapSec.detalle.map((poligono,index) => 
      {  const auxCoord=generaCoordenadaDinam(poligono.Lat, poligono.Lon) 
        return(
        <Marker style={{width:40+'px',height:40+'px'}}
          title={`${poligono.Titulo}`}   key={poligono.Id}
          position={ auxCoord}   
          onClick={()=>formatoInfo(poligono)}  
          icon= {{url:`iconos/cine_ic.png`}} 
       /> )
      })      
      :null
    }    

    { rutaPol!==null && rutaPol.length!==0?
      rutaPol.map((poligono) => 
      {         
        return(   
        <Marker style={{width:80+'px',height:80+'px'}}
          title={`${poligono.secc}`}   key={poligono.Id}
          position={ poligono.centro}   
          icon= {{url:`https://daduva-cartografia.sfo3.cdn.digitaloceanspaces.com/Sec/Secc${poligono.secc}.svg`}} 
       />   )
      })      
      :null 
      }   
    </GoogleMap>
  </LoadScript>);
}
 