export function ordenIdColor(MuniNum){    
    let auxNvo =MuniNum
    let nvoRespuesta=""
/*     if (auxNvo===25) //si es cuatitlan izcalli se cambia a 121
    { auxNvo=121
    }
    else
    { if (auxNvo>25 && auxNvo<=121 && auxNvo!==59) 
      { if (auxNvo===60) 
        { auxNvo=58   
        }
        else
        { auxNvo=auxNvo-1
        }
      }
    } */
   /*  if (auxNvo===108) 
    { auxNvo=20
    } */        
    if (auxNvo<10) {
        nvoRespuesta="00"+auxNvo
    }
    else{
        if (auxNvo<100) {
            nvoRespuesta= "0"+auxNvo
        }
        else{                    
            nvoRespuesta=""+auxNvo
        }
    }
    return nvoRespuesta
  }
  export function ordIdColRepublica(MuniNum){    
    let auxNvo = MuniNum, nvoRespuesta=""
    if (auxNvo<10) {
        nvoRespuesta= "0"+auxNvo
    }
    else{                    
        nvoRespuesta=""+auxNvo
    }                
    return nvoRespuesta
}

export function ordIdColDinamico(MuniNum,maximo){    
    let auxNvo =MuniNum
    let nvoRespuesta=""
    if (maximo>99) {
        if (auxNvo<10) {
            nvoRespuesta="00"+auxNvo
        }
        else{
            if (auxNvo<100) {
                nvoRespuesta= "0"+auxNvo
            }
            else{                    
                nvoRespuesta=""+auxNvo
            }
        }    
    }
    else{
        if (auxNvo<100) {
            nvoRespuesta= "0"+auxNvo
        }
    }
    
    return nvoRespuesta
}
export  function ordenMapBx2(arreglo,valor,color){
    let filtrado=arreglo.findIndex((elemt)=>elemt.auxColor===color)    
    if (filtrado ===-1) {
        arreglo.push({pos:[valor],auxColor:color})                                
    }else{
        arreglo[filtrado].pos.push(valor)                                                                                                                                                
    }   
}

export  function ordenMapBx(arreglo,valor,color){
    let filtrado=arreglo.findIndex((elemt)=>elemt.auxColor===color)
    if (filtrado ===-1) {
        arreglo.push({pos:[valor],auxColor:color})                                
    }else{
        arreglo[filtrado].pos.push(valor)                                                                                                                                                
    }   
}